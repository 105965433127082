import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
// import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
// import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
// import Testimonials from "pages/Presentation/sections/Testimonials";
// import Download from "pages/Presentation/sections/Download";
// import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";
import Header from "components/Header/Header";
import footerRoutes from "footer.routes";
// import { getYouTubeVideoCount } from "logic/SocialMediaApis";
import bgContainer from "assets/images/cocktails-background.jpg";

const Presentation = () => {
  // const [youtubeVideoCount, setYoutubeVideoCount] = useState(0);

  // useEffect(() => {
  //   // Fetch the YouTube video count when the component mounts
  //   setYoutubeVideoCount(getYouTubeVideoCount());
  // }, []);
  return (
    <>
      <Header
        title="Pink Flamingo Studios"
        description="Unlock Your Digital Potential: Connect, Engage, and Grow"
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: () => `url(${bgContainer})`,
          backgroundSize: "cover",
          backfaceVisibility: "hidden",
        }}
      >
        {/* <Counters youtubeVideoCount={youtubeVideoCount} /> */}
        <Information />
        {/* <DesignBlocks /> */}
        <Pages />
        {/* <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container> */}
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="flag"
                title="Free Tips"
                description="Discover our valuable social media advertising tips!"
                action={{
                  type: "external",
                  route: "https://www.instagram.com/p/CnKhKanJTCa/",
                  label: "Learn More",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="tiktok"
                title="Check Us Out on TikTok"
                description="Follow us on TikTok and stay up to date with our newest content and promotions!"
                action={{
                  type: "external",
                  route: "https://www.tiktok.com/@pinkflamingo.studios",
                  label: "Let's Go!",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                color="info"
                icon="group"
                title="Meet our extraordinary team!"
                description="Discover the magic behind Pink Flamingo Studios as you meet our talented team!"
                action={{
                  type: "internal",
                  route: "/pages/landing-pages/about-us",
                  label: "Meet The Team",
                }}
              />
            </Grid>
          </Grid>
        </Container>
        {/* <Testimonials /> */}
        {/* <Download /> */}
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Sharing is Caring: Help Us Spread the Word!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  If you find our content helpful, please consider sharing it with your friends by
                  clicking the share button. Help us spread the word!
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=https%3A%2F%2Fpfsocialmedia.com%2F&display=popup&ref=plugin&src=share_button"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Share
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default Presentation;
