/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Custom Components
import Header from "components/Header/Header";

// About Us page sections
import Information from "pages/LandingPages/AboutUs/sections/Information";
import Team from "pages/LandingPages/AboutUs/sections/Team";
import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";

// Routes
import footerRoutes from "footer.routes";

import bgContainer from "assets/images/cocktails-background.jpg";

function AboutUs() {
  return (
    <>
      <Header
        title="Let Us Grow Your Business"
        description="We leverage social platforms to generate revenue-oriented communications for your company's community, customers, and clients."
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          backgroundImage: () => `url(${bgContainer})`,
          backgroundSize: "cover",
          backfaceVisibility: "hidden",
        }}
      >
        <Information />
        <Team />
        <Newsletter />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
