/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Data
import data from "pages/Presentation/sections/data/pagesData";

function Pages() {
  const renderData = data.map(({ image, name, description, title }) => (
    <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
      <HorizontalTeamCard
        image={image}
        name={name}
        position={{ color: "info", label: title }}
        description={description}
      />
    </Grid>
  ));

  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      marginBottom="4rem"
      sx={{ opacity: "0.9" }}
    >
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75, opacity: "1" }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="INFINITE POSSIBILITIES"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold" color="white">
            WITH OUR TEAM OF SPECIALISTS
          </MKTypography>
          <MKTypography variant="body1" color="white">
            we&apos;re here to make your brand stand out. From captivating social media campaigns to
            enchanting web designs, we&apos;ve got the magic touch to elevate your business to new
            heights let&apos;s work together and turn your visions into reality!
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              {renderData}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1} color="white">
                Flamingo Services
              </MKTypography>
              <MKTypography variant="body2" fontWeight="regular" color="white" mb={1} pr={2}>
                At Pink Flamingo Studios, we prioritize your success in every marketing campaign.
                Our commitment to building lasting relationships with clients drives us to provide
                the perfect resources tailored to your needs. Count on us to empower your journey
                towards achievement!
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Pages;
