import React from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SocialMediaIcons from "components/SocialMedia/SocialMediaIcons";
import TeamAvatars from "components/TeamAvatars/TeamAvatars";
import routes from "routes";
import bgImage from "assets/images/bg-about-us.jpg";

const Header = ({ title, description, contactInformation }) => {
  return (
    <>
      <DefaultNavbar routes={routes} transparent light brand="Pink Flamingo Studios" />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {title}
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              {description}
              <TeamAvatars size="sm" />
            </MKTypography>
            {!contactInformation ? (
              <>
                <MKTypography variant="h6" color="white" mt={1} mb={1}>
                  Find us on
                </MKTypography>
                <SocialMediaIcons />
              </>
            ) : (
              <MKTypography variant="h6" color="white" mt={1} mb={1}>
                {contactInformation?.map((info) => (
                  <ul key={info}>{info}</ul>
                ))}
              </MKTypography>
            )}
          </Grid>
        </Container>
      </MKBox>
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  contactInformation: PropTypes.array,
};

export default Header;
