import React from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const SocialMediaIcons = () => {
  return (
    <>
      <MKBox display="flex" justifyContent="center" alignItems="center">
        <MKTypography
          component="a"
          variant="body1"
          color="white"
          href="https://www.instagram.com/pinkflamingo.studios/"
          mr={3}
          target="_blank"
        >
          <i className="fab fa-instagram" />
        </MKTypography>

        <MKTypography
          component="a"
          variant="body1"
          color="white"
          href="https://www.youtube.com/@pinkflamingostudiosllc"
          target="_blank"
          mr={3}
        >
          <i className="fab fa-youtube" />
        </MKTypography>
        <MKTypography
          component="a"
          variant="body1"
          color="white"
          href="https://www.linkedin.com/company/pink-flamingo-studios-kc"
          target="_blank"
          mr={3}
        >
          <i className="fab fa-linkedin" />
        </MKTypography>
        <MKTypography
          component="a"
          variant="body1"
          color="white"
          href="https://www.tiktok.com/@pinkflamingo.studios"
          target="_blank"
        >
          <i className="fab fa-tiktok" />
        </MKTypography>
      </MKBox>
    </>
  );
};

export default SocialMediaIcons;
