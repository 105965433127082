/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/alex-roberts.jpg";
import team2 from "assets/images/megan-nelson.jpg";
import team3 from "assets/images/jr-kim.jpg";
import team4 from "assets/images/jacey.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6, opacity: "0.9" }}>
            <MKTypography variant="h3" color="white">
              The Team
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Introducing our skilled team, prepared to guide you every step of the way.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Alex Roberts"
                position={{ color: "info", label: "Owner (Axl)" }}
                description="Introducing Alex, the Horseshoe Champion and Marketing Wizard at Pink Flamingo Studios! With a flair for captivating audiences, Alex's marketing expertise will work wonders for your brand. When not conquering the Lakes of the Ozarks, they're rocking the mic as a Karaoke DJ and soaring as a Zip-line Guide. With a Bachelor's degree in Public Relations and Journalism, Alex is a dynamic all-rounder, ready to make your brand shine! Get ready for an extraordinary marketing adventure with Alex at your side!"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Megan Nelson"
                position={{ color: "info", label: "Owner (Queen)" }}
                description="Meet Megan, the undefeated Online Marketing Cruiserweight Champion! From global giants to mom and pop shops, she's knocked out countless successful campaigns. With a Master's degree in Communication Management from USC and Manga Cum Laude graduation from Hawaii Pacific University, Megan's marketing superpowers are unbeatable. Your brand will soar to new heights with her knockout punch! Get ready for a non-stop marketing adventure like no other—Megan guarantees success! "
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="JR Kim"
                position={{ color: "info", label: "CTO (Software Daddy)" }}
                description="Introducing JR, the Tech Guru at Pink Flamingo Studios! As the Chief Technology Officer, JR is the mastermind behind our captivating technology. With a diverse background, JR brings a unique perspective to the digital realm. Their skills and experience make them a highly accomplished professional, ensuring excellence for our clients. From front-end magic to back-end brilliance, JR's expertise will elevate your brand to new heights!"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Jacey Fernandez"
                position={{ color: "info", label: "Account Manager" }}
                description="Meet Jacey, the absolute artistic fairy who waves her wand in both the content ambassador and account manager role in Honolulu, Hawaii. When not holding the sparkly wand, Jacey is singing, dancing, and exploring the ʻĀina🌺 With a Bachelor’s in Psychology from Hawaii Pacific University, Jacey sprinkles her creative approach. With the help of her golden-like glitter, your account will be indeed magical and elevated."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
