/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Image assets
import CardImage from "assets/images/three-reasons.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center" sx={{ opacity: "0.9" }}>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <FilledInfoCard
                      color="info"
                      icon="tv"
                      title="Video Oriented"
                      description="We use video to boost viewership and virality on all social media platforms, optimizing your online presence"
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <FilledInfoCard
                      color="info"
                      icon="payments"
                      title="Original Content"
                      description="We will film and capture original content for your company, a service few agencies offer, as a standard service"
                    />
                  </MKBox>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <FilledInfoCard
                      color="info"
                      icon="apps"
                      title="Web Applications"
                      description="We specialize in providing top-notch website maintenance and development services, all meticulously tailored to your unique needs and requirements!"
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <FilledInfoCard
                      color="info"
                      icon="camera"
                      title="Camera Facing Performance"
                      description="We're full-service and not camera-shy! We'll perform and influence for your company, delivering your message to your community comprehensively."
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={CardImage}
              title="Contact Us"
              description="Phone Number: (808) 499-4535"
              descriptionMultiLine={["Social Media Advertising:", "(808) 499-4535"]}
              action={{
                type: "internal",
                route: "/pages/landing-pages/contact-us",
                color: "info",
                label: "Contact Us",
              }}
              showButton={true}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
