import React from "react";
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import TeamPhotos from "components/TeamAvatars/photos";
import Grid from "@mui/material/Grid";
import MuiAvatarGroup from "@mui/material/AvatarGroup";

const TeamAvatars = ({ size }) => {
  const renderAvatars = () => {
    return TeamPhotos.map((photo, index) => (
      <MKAvatar src={photo} alt={photo} key={index} size={size} />
    ));
  };

  return (
    <MKBox display="flex" justifyContent="center" alignItems="center" marginTop="1rem">
      <Grid container justifyContent="center">
        <MuiAvatarGroup spacing={12}>{renderAvatars()}</MuiAvatarGroup>
      </Grid>
    </MKBox>
  );
};

TeamAvatars.propTypes = {
  size: PropTypes.string.isRequired,
};

export default TeamAvatars;
