/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Images
import bgFront from "assets/images/social-blocks.png";
import bgBack from "assets/images/social-blocks.png";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Empower
                    <br />
                    Your Growth
                  </>
                }
                description="Our team of specialists expertly manages all social media platforms, curating original and engaging content to elevate your online presence."
              />
              <RotatingCardBack
                image={bgBack}
                title="Social Media Specialists"
                description="Our dedicated team of social media specialists excels in managing all your social media platforms with creativity and finesse. We pride ourselves on crafting original and captivating content that resonates with your audience."
                action={{
                  type: "internal",
                  route: "/pages/landing-pages/about-us",
                  label: "Learn More",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <FilledInfoCard
                  color="info"
                  icon="connect_without_contact"
                  title="Craft Your Unique Content"
                  description="Unleash the magic of social media with our expert team of content creators. From captivating visuals to compelling copy, we breathe life into your brand with unique and engaging content that leaves a lasting impression."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FilledInfoCard
                  color="info"
                  icon="group"
                  title="Face-to-Face Analytics Meetings"
                  description="Experience the power of face-to-face interactions as we dive into data analytics during our dynamic meetings. Uncover valuable insights, strategize effectively, and make data-driven decisions that drive your business forward."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <FilledInfoCard
                  color="info"
                  icon="access_time"
                  title="Unlock Your Potential"
                  description="Save time and elevate your brand with our expert marketing strategies and tailored website solutions. Unleash your full digital potential and achieve remarkable success and recognition today!"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FilledInfoCard
                  color="info"
                  icon="devices"
                  title="Software Development"
                  description="Did you enjoy our page? At Pink Flamingo Studios, we take pleasure in tailoring websites to suit your unique requirements. Let us create the perfect website that matches your vision and goals."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
