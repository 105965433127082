import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Header from "components/Header/Header";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
// import { toast, ToastContainer } from "react-toastify";
import emailjs from "@emailjs/browser";
import bgImage from "assets/images/illustrations/illustration-reset.jpg";

function ContactUs() {
  const serviceID = "service_jm742jw";
  const templateID = "template_ycx3xc9";
  const userID = "ORwR7Mol35bKUIquo";

  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [body, setBody] = useState("");
  const [invalidFields, setInvalidFields] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);

  const contactInformation = [
    "Social Media Advertising: (808) 499-4535",
    "Website Development: (785) 375-3759",
    "pinkflamingostudioskc@gmail.com",
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !phoneNumber || !email || !body) setInvalidFields(true);
    else {
      setInvalidFields(false);
      const emailParams = {
        from_name: name,
        to_name: "Pink Flamingo Studios",
        reply_to: email,
        message: `Name: ${name} \n Phone Number: ${phoneNumber} \n Email: ${email} \n Message: ${body}`,
      };

      emailjs
        .send(serviceID, templateID, emailParams, userID)
        .then((response) => {
          setMessageSuccess(true);
          console.log("Email sent successfully:", response);
        })
        .catch((error) => {
          setMessageSuccess(false);
          console.error("Failed to send email:", error);
        });
    }
  };

  return (
    <>
      <Header
        title="Contact Us"
        description="Get in touch with us! Drop us a message below, and we'd be thrilled to hear from you!"
        contactInformation={contactInformation}
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <MKBox
              display={{ xs: "none", lg: "flex" }}
              width="calc(100% - 2rem)"
              height="calc(80vh - 2rem)"
              borderRadius="lg"
              ml={2}
              mt={2}
              sx={{ backgroundImage: `url(${bgImage})`, backgroundRepeat: "no-repeat" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={10}
            md={7}
            lg={6}
            xl={4}
            ml={{ xs: "auto", lg: 6 }}
            mr={{ xs: "auto", lg: 6 }}
          >
            <MKBox
              bgColor="white"
              borderRadius="xl"
              shadow="lg"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              mt={{ xs: 20, sm: 18, md: 20 }}
              mb={{ xs: 20, sm: 18, md: 20 }}
              mx={3}
            >
              <MKBox
                variant="gradient"
                bgColor="dark"
                coloredShadow="info"
                borderRadius="lg"
                p={2}
                mx={2}
                mt={-3}
              >
                <MKTypography variant="h3" color="white">
                  Contact us
                </MKTypography>
              </MKBox>
              <MKBox p={3}>
                <MKTypography variant="body2" color="text" mb={3}>
                  Share your details below, and we&apos;ll reach out to you in no time! Looking
                  forward to connecting with you!
                </MKTypography>
                <MKBox width="100%" component="form" method="post" autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Full Name"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        type="email"
                        variant="standard"
                        label="Email"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        type="phone"
                        variant="standard"
                        label="Phone Number"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        variant="standard"
                        label="Let's Weave Marketing Magic!"
                        placeholder="We're thrilled to connect with you!"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        fullWidth
                        rows={6}
                        onChange={(e) => setBody(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <MKTypography variant="body2" color="info" mb={3}>
                    <div>
                      {invalidFields &&
                        "Please make sure to fill all the fields before continuing."}
                      {messageSuccess && "Message Sent!"}
                    </div>
                  </MKTypography>
                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                    <MKButton type="submit" variant="gradient" color="info" onClick={handleSubmit}>
                      Send Message
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </Card>
    </>
  );
}

export default ContactUs;
