// @mui icons
// import InstagramIcon from "@mui/icons-material/Instagram";
// import YouTubeIcon from "@mui/icons-material/YouTube";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Pages
// import AboutUs from "layouts/pages/landing-pages/about-us";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
// import logo from "assets/images/apple-icon.png";

const date = new Date().getFullYear();

export default {
  brand: {
    // name: "Pink Flamingo Studios",
    // image: logo,
    // route: "/",
  },
  socials: [
    // {
    //   icon: <InstagramIcon />,
    //   link: "https://www.instagram.com/pinkflamingo.studios/",
    // },
    // {
    //   icon: <LinkedInIcon />,
    //   link: "https://www.linkedin.com/company/pink-flamingo-studios-kc/",
    // },
    // {
    //   icon: <YouTubeIcon />,
    //   link: "https://www.youtube.com/@pinkflamingostudiosllc",
    // },
    // {
    //   icon: <i className="fab fa-tiktok" />,
    //   link: "https://www.tiktok.com/@pinkflamingo.studios",
    // },
  ],
  menus: [
    // {
    //   name: "company",
    //   items: [
    //     { name: "about us", route: "/pages/landing-pages/about-us", component: <AboutUs /> },
    //     // { name: "Services", route: "/pages/landing-pages/about-us", component: <AboutUs /> },
    //   ],
    // },
    // {
    //   name: "resources",
    //   items: [
    //     { name: "illustrations", href: "https://iradesign.io/" },
    //     { name: "bits & snippets", href: "https://www.creative-tim.com/bits" },
    //     { name: "affiliate program", href: "https://www.creative-tim.com/affiliates/new" },
    //   ],
    // },
    // {
    //   name: "help & support",
    //   items: [
    //     { name: "contact us", href: "https://www.creative-tim.com/contact-us" },
    // { name: "knowledge center", href: "https://www.creative-tim.com/knowledge-center" },
    // { name: "custom development", href: "https://services.creative-tim.com/" },
    // { name: "sponsorships", href: "https://www.creative-tim.com/sponsorships" },
    //   ],
    // },
    // {
    //   name: "legal",
    //   items: [
    //     { name: "terms & conditions", href: "https://www.creative-tim.com/terms" },
    //     { name: "privacy policy", href: "https://www.creative-tim.com/privacy" },
    //     { name: "licenses (EULA)", href: "https://www.creative-tim.com/license" },
    //   ],
    // },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}{" "}
      <MKTypography
        component="a"
        href="https://www.linkedin.com/in/jr-kim-235b0496/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        JR Kim
      </MKTypography>
      .
    </MKTypography>
  ),
};
