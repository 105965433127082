import socialMediaManagementImage from "assets/images/services-social-media-management.jpg";
import videoContentCreationImage from "assets/images/video-content-creation.jpg";
import productBrandImage from "assets/images/product-brand.jpg";
import adBudget from "assets/images/ad-budget.jpg";
import communityManagement from "assets/images/community-management.jpg";

export default [
  {
    image: socialMediaManagementImage,
    name: "Social Media Management",
    description:
      "Maximize your reach on tailored Social Media platforms—Facebook, Instagram, TikTok, YouTube & more. Comprehensive operation & management.",
    title: "",
  },
  {
    image: videoContentCreationImage,
    name: "Video Content Creation",
    description:
      "Unlock your brand's potential with on-site content creation, custom video editing, full content ownership, and expert social media management.",
    title: "",
  },
  {
    image: productBrandImage,
    name: "Product and Brand Oriented Original Content",
    description:
      "Elevate your brand with personalized content tailored to showcase your products and enhance your brand image. Engage your audience with originality!",
    title: "",
  },
  {
    image: adBudget,
    name: "Social Media Ad Budget",
    description:
      "Tailor-made content, posts, and videos crafted to align perfectly with your company's vision and goals. Unleash your brand's full potential and success with Pink Flamingo Studios!",
    title: "",
  },
  {
    image: communityManagement,
    name: "Community Management And Engagement",
    description:
      "Unlock maximum viewership, engagement, and revenue with our expertly managed paid advertising on multiple platforms. Elevate your business!",
    title: "",
  },
  // {
  //   image:
  //     "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/account/signin-basic.jpg",
  //   name: "Sign In Page",
  //   route: "/pages/authentication/sign-in",
  // },
  // {
  //   image: `${imagesPrefix}/author.jpg`,
  //   name: "Contact Us",
  //   route: "/pages/landing-pages/author",
  // },
  // {
  //   image: `${imagesPrefix}/contact.jpg`,
  //   name: "Contact Us Page",
  //   route: "/pages/landing-pages/contact-us",
  // },
];
